import history from '../../history'

/*

$redirect request
{
  location: string
}
*/
export default store => next => action => {

    if(!action.$redirect) {
        return next(action);
    }

    next(action);
    history.push(action.$redirect.location);

    // fetch REQUEST
    return next({
        type: action.type + "/REDIRECT",
        payload: action.payload,
        data: action.$redirect
    });


}