import * as types from './types';
import SostaOnlineApiService from '../services/SostaOnlineApiService';
import {stopSubmit} from 'redux-form'

export const loginRequest = (credentials) => {
    return {
        type: types.AUTH_LOGIN_REQUEST,
        credentials: credentials
    }
};

export const loginRequestSuccess = (data) => {
    return {
        type: types.AUTH_LOGIN_SUCCESS,
        data: data
    }
};

export const logout = () => {
    return {
        type: types.AUTH_LOGOUT
    }
};

export const saveCredentials = (credentials) => {
    return {
        type: types.AUTH_SAVE_CREDENTIALS,
        credentials: credentials
    }
};

export const clearCredentials = (credentials) => {
    return {
        type: types.AUTH_CLEAR_CREDENTIALS
    }
};


export const doLogout = () => {
    return(dispatch, getState) => {
        return new Promise(resolve => {
            dispatch(logout());
            resolve();
        });
    }
};


export const doLogin = (credentials) => {
    return (dispatch, getState) => {
        return SostaOnlineApiService.login(credentials.username, credentials.password)
            .then((data) => {
                SostaOnlineApiService.setToken(data.token);
                dispatch(loginRequestSuccess(data));
            })
            .catch((err) => {
                console.log("Errore LoginForm", err);
                dispatch(stopSubmit("login", {_error: "Username o Password errati"}));
            });
    };
};