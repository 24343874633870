
// TYPES
export const types = {
    FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA: "MARK_TYPE/FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA",
};

// INITIAL STATE
export const initialState = {
    data: null,
    loading: true,
    error: null,
};

// REDUCER
export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA + "/REQUEST":
            return {
                ...state,
                loading: true,
                data: []
            };
        case types.FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA + "/SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.data.data
            };
        case types.FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA + "/FAILURE":
            return {
                ...state,
                //data: [],
                loading: false,
                error: true
            };

        default:
            return state
    }
}

// ACTIONS
export const actions = {

    fetchMarkTypeByCategoryAndAreaId: (category, areaId) => {
        return {
            type: types.FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA,
            $fetch: {
                url: '/api/v1/markTypes?areaId=' + category + '&category=' + areaId,
                method: 'GET'
            }
        };
    },

    saveMarkTypes: (markTypes) => {
        return {
            type: types.FETCH_MARK_TYPE_BY_CATEGORY_AND_AREA + "/SUCCESS",
            data: {
                data: markTypes
            }
        }
    }
};