
// TYPES
export const types = {
    FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK: "MARK_RULES/FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK",
};

// INITIAL STATE
export const initialState = {
    data: null,
    loading: true,
    error: null,
};

// REDUCER
export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK + "/REQUEST":
            return {
                ...state,
                loading: true,
                data: null
            };
        case types.FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK + "/SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.data.data
            };
        case types.FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK + "/FAILURE":
            return {
                ...state,
                data: null,
                loading: false,
                error: true
            };

        default:
            return state
    }
}

// ACTIONS
export const actions = {

    fetchMarkRulesByCategoryAndAreaAndMark: (category, areaId, markType) => {
        return {
            type: types.FETCH_MARK_RULES_BY_CATEGORIES_AND_AREA_AND_MARK,
            $fetch: {
                url: '/api/v1/markRules?areaId=' + areaId + '&category=' + category + '&markType=' + markType,
                method: 'GET'
            }
        };
    },
};