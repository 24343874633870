import React, {Component} from 'react'
import { NavLink } from 'react-router-dom'

import './CookieLawBanner.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {actions} from '../redux/modules/CookieLaw'
import moment from 'moment'

const mapStateToProps = (state) => {
  return {
    state: state.cookieLaw
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

class CookieLawBanner extends Component {
  constructor (props) {
    super(props)

    if (props.state.lastUpdate) {
      if (moment().diff(moment(props.state.lastUpdate), 'months') >= 1) {
        this.props.actions.reset()
      }
    }
  }

  render () {
    if (!this.props.state.understand) {
      return (
        <div id="cookie-statement">
          <div className="cookie-statement-text">
          <span style={{marginRight: '10px'}}>
            Questo sito utilizza cookie tecnici e di terze parti. Proseguendo nella navigazione, accetti l'utilizzo dei cookie da parte nostra.
          </span>
            <NavLink className="cookie-banner-btn" to={'/cookie-police'}>
              <span>Maggiori Informazioni</span>
            </NavLink>
            <span className="cookie-banner-btn" onClick={() => this.props.actions.setIUnderstand()}>Ho Capito</span>
          </div>
        </div>
      )
    }

    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieLawBanner)