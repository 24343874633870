
// TYPES
export const types = {
    FETCH_AREAS: "AREA/FETCH_AREAS",
};

// INITIAL STATE
export const initialState = {
    data: [],
    loading: false,
    error: null,
};

// REDUCER
export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_AREAS + "/REQUEST":
            return {
                ...state,
                loading: true
            };
        case types.FETCH_AREAS + "/SUCCESS":
            return {
                ...state,
                loading: false,
                data: action.data.data
            };
        case types.FETCH_AREAS + "/FAILURE":
            return {
                ...state,
                loading: false,
                error: true
            };

        default:
            return state
    }
}

// ACTIONS
export const actions = {

    fetchAreas: () => {
        return {
            type: types.FETCH_AREAS,
            $fetch: {
                url: '/api/v1/areas',
                method: 'GET'
            }
        };
    },
};